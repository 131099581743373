<template>
  <section class="blog-list">
    <h4 class="blog-list__title font-bold">Conteúdos que Transformam</h4>

    <div class="blog-list__content">
      <div class="blog-list__content__main">
        <NuxtImg
          class="blog-list__content__main__img"
          :src="articleMain.imageUrlArticleFirst"
          alt="Imagem do blog"
          loading="lazy"
          quality="100"
          sizes="xs:100vw sm:100vw md:100vw lg:100vw xl:100vw"
        />

        <div class="blog-list__content__main__content">
          <NuxtLink :to="`/blog/${articleMain.normalizedTitle}`">
            <h5 class="blog-list__content__main__content__title cursor-pointer font-bold">
              {{ articleMain.title }}
            </h5>
          </NuxtLink>
          <div class="blog-list__content__main__content__by">
            <BlogSharedAuthorSmall :profile="articleMain.author.srcUrlImage" :name="articleMain.author.name" />
          </div>
          <p class="blog-list__content__main__content__text">
            {{ articleMain.description }}
          </p>
          <NuxtLink class="blog-list__content__list__link font-bold" :to="`/blog/${articleMain.normalizedTitle}`">
            {{ $t('message.products.knowMore') }}
            <span v-html="arrow"></span>
          </NuxtLink>
        </div>
      </div>

      <div class="blog-list__content__list">
        <div v-for="(article, index) in articles" :key="article.blogId" class="blog-list__content__list__article">
          <NuxtImg
            v-if="index == 0"
            class="blog-list__content__list__image"
            :src="article.imageUrlArticleFirst"
            alt="imagem do artigo"
            loading="lazy"
            quality="100"
            sizes="xs:100vw sm:100vw md:100vw lg:100vw xl:100vw"
          />
          <h5 class="blog-list__content__list__title font-bold">
            {{ article.title }}
          </h5>
          <NuxtLink class="blog-list__content__list__link font-bold" :to="`/blog/${article.normalizedTitle}`">
            {{ $t('message.products.knowMore') }}
            <span v-html="arrow"></span>
          </NuxtLink>
          <hr />
        </div>
      </div>
    </div>

    <VButton color="fourth" class="blog-list__button font-bold" to="/blog"> Veja Mais no Blog da SIS </VButton>
  </section>
</template>

<script setup>
const blogStore = useBlogStore()
const arrow =
  ref(`<svg style="align-self: center;" xmlns="http://www.w3.org/2000/svg" width="14.002" height="13.647" viewBox="0 0 14.002 13.647">
            <path id="Caminho_816" data-name="Caminho 816"
            d="M5.953-11.909a.745.745,0,0,0,.013,1.072L9.731-7.25H.75A.748.748,0,0,0,0-6.5v1a.748.748,0,0,0,.75.75H9.731L5.966-1.163A.751.751,0,0,0,5.953-.091L6.647.6A.747.747,0,0,0,7.706.6l6.075-6.075a.747.747,0,0,0,0-1.059L7.706-12.6a.747.747,0,0,0-1.059,0Z"
            transform="translate(0 12.823)" fill="#960B38" />
            </svg>`)
const articles = reactive(blogStore.articles.slice(1))
const articleMain = reactive(blogStore.articles[0])
</script>

<style scoped lang="scss">
.blog-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  width: 100%;
  margin: 37px 0px;

  &__title {
    font: normal normal 900 40px/49px Montserrat;
    letter-spacing: 0px;
    color: $red;
    text-align: center;
  }

  &__content {
    display: flex;
    align-items: flex-start;
    gap: 16px;
    width: fit-content;

    &__main {
      display: flex;
      flex-direction: column;
      box-sizing: border-box;
      width: 100%;
      max-width: 628px;

      &__img {
        object-fit: cover;
        width: 100%;
        height: 407px;
      }

      &__content {
        padding: 10px 20px;
        display: flex;
        flex-direction: column;
        gap: 16px;

        &__title {
          font: normal normal 900 35px/40px Montserrat;
          letter-spacing: 0px;
          color: #1f214a;
        }

        &__text {
          text-align: left;
          font: normal normal normal 16px/24px Montserrat;
          letter-spacing: 0px;
          color: #323232;
        }

        &__by {
          display: flex;
          align-items: center;
          font: normal normal normal 16px/24px Montserrat;
          color: #707070;

          span {
            font: normal normal 900 16px/24px Montserrat-ExtraBold;
            color: #707070;
            margin-right: 5px;
          }

          img {
            height: 30px;
            width: 30px;
            border-radius: 50%;
            margin-right: 10px;
          }
        }

        &__labels {
          display: flex;
          gap: 8px;
          position: relative;
          margin-top: -20px;

          &__item {
            border-radius: 11px;
            padding: 5px 11px;
            font: normal normal normal 12px/22px Montserrat;
            letter-spacing: 0px;
            text-transform: uppercase;
            cursor: pointer;
          }
        }
      }
    }

    &__list {
      width: 100%;
      max-width: 260px;

      &__article {
        display: flex;
        flex-direction: column;
        gap: 4px;

        hr {
          margin-top: 10px;
          margin-bottom: 10px;
          background: #707070;
          height: 1px;
          border: 0px;
          width: 100%;
          opacity: 0.25;
        }
      }

      &__image {
        width: 100%;
        height: 169px;
        object-fit: cover;
      }

      &__title {
        font: normal normal 900 16px/24px Montserrat;
        letter-spacing: 0px;
        color: #1f214a;
      }

      &__link {
        display: flex;
        gap: 6px;
        align-items: center;
        font: normal normal 900 16px/24px Montserrat;
        letter-spacing: 0px;
        color: #960b38 !important;
      }
    }
  }

  &__button {
    font: normal normal 900 16px/19px Montserrat;
    letter-spacing: 0px;
    width: 352px;
    height: 56px;
    border-radius: 41px !important;
  }
}

@media screen and (max-width: $mobile) {
  .blog-list {
    &__title {
      font: normal normal 900 20px/24px Montserrat;
    }

    &__content {
      flex-direction: column;

      &__main {
        padding: 14px;

        &__img {
          width: 100%;
          height: 224px;
        }

        &__content {
          &__title {
            font: normal normal 900 20px/24px Montserrat;
          }

          &__text {
            font: normal normal normal 16px/24px Montserrat;
          }
        }
      }

      &__list {
        max-width: 100%;
        padding: 0px 30px;

        &__image {
          width: 100%;
          height: 224px;
          margin-bottom: 21px;
          display: none;
        }
      }
    }

    &__button {
      width: 90%;
    }
  }
}
</style>
